<template>
  <div>
    <div class="card">
      <div class="card-body">
        <table class="table datatable" ref="datatable">
          <thead>
            <tr>
              <th>{{ $t("FORM.ID") }}</th>
              <th>{{ $t("FORM.FULL_NAME") }}</th>
              <th>{{ $t("FORM.EMAIL") }}</th>
              <th>{{ $t("FORM.PHONE") }}</th>
              <th>{{ $t("LIST.CREATED_AT") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.id }}</td>
              <td>{{ user.firstName }} {{ user.lastName }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone }}</td>
              <td>
                {{ user.createdAt | moment("DD.MM.YYYY, HH:mm") }}
              </td>
              <td class="px-0 text-center">
                <button
                  class="edit-button"
                  @click.stop.prevent="undeleteUser(user.id)"
                >
                  <b-icon
                    icon="arrow-counterclockwise"
                    variant="primary"
                    font-scale="1.3"
                  ></b-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      users: {}
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ayarlar" },
      { title: "Kullanıcılar" },
      { title: "Silinmiş Kullanıcılıar" }
    ]);
  },
  methods: {
    ...mapActions({
      getDeletedYsers: "company/GET_DELETED_USERS",
      undeleteSelectedUser: "company/UNDELETE_USER"
    }),
    undeleteUser(id) {
      this.undeleteSelectedUser(id).then(() => {
        this.$router.replace({ name: "settings-users" });
      });
    }
  },
  async created() {
    this.users = await this.getDeletedYsers();
  }
};
</script>

<style lang="scss" scoped>
.edit-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
</style>
